<template>
    <apexchart type="line" :options="miniChartOptions" :series="miniData.series" height="100%" width="100%"></apexchart>
</template>

<script>
// Services
import apexchart from 'vue-apexcharts'

export default {
  name: 'PlayoutMasterChart',
  props: {
    miniData: {
      type: Object,
      required: true
    },
    strokeColour: {
      type: String,
      required: false,
      default: '#1c1c1c'
    }
  },
  data: () => ({
  
  }),
  components: {
      apexchart
  },
  mounted() {
  },
  computed: {

    miniChartOptions() {
      let res = {
        chart: {
          id: 'miniChart',
          type: 'area',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        stroke: {
          width: 10,
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: this.miniData.categories,
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      }
      return res;
    },
  },

  methods: {

  }
}
</script>