<template>
        <v-col cols="12" style="display:flex">
        <v-chip
          class="mr-2"
          color="primary"
          :close="false"
          label
          link
          outlined
        ><v-icon left small style="margin-right: 5px;margin-left:-2px;margin-top: 1px">mdi-calendar-range</v-icon>
          <span style="font-size: 12px;">{{ defaultDateTimeRange }}</span>
          <v-btn small fab icon style="margin-left:-6px;margin-right:-17px;"><v-icon small style="margin-top: 1px">mdi-close</v-icon></v-btn>
        </v-chip>
          <v-chip v-if="false"
          class="mr-2"
          :close="false"
          label
          link
        >        

        </v-chip>
        <v-chip
          class="mr-2"
          :close="false"
          label
          link
          outlined
        ><v-icon left small style="margin-right: 5px;margin-left:-2px;margin-top: 1px">mdi-account-group-outline</v-icon>
          <span style="font-size: 12px;">No Audience String</span>
          <v-switch style="scale:0.5;margin:0px;margin-right:-20px; padding: 0px;"
            hide-details
            inset
          ></v-switch>
          <v-btn small fab icon style="margin-left:-6px;margin-right:-17px;"><v-icon small style="margin-top: 1px">mdi-close</v-icon></v-btn>

        </v-chip>
        <v-chip
          class="mr-2"
          :close="false"
          label
          link
          outlined
        ><v-icon small style="margin-right: -2px;margin-left:-2px;margin-top: 1px">mdi-plus</v-icon>
        </v-chip>
      </v-col>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'PlayoutFilters',
  components: {
  },
  props: {
    initializedSupportInfo: {
      type: Object,
      required: true,
    },
    defaultDateTimeRange: {
      type: Array, // [start, end] datetimes
      required: true,
    }
  },
  data() {
    return {
      currentSelection: null,
    };
  },
  computed: {
   
  },
  mounted() {
    console.log(this.initializedSupportInfo)
  },
  methods: {

  },
};
</script>
