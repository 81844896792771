<template>
  <div class="playout-dashboard-container" ref="playoutDashboardContainer">
    <v-card style="padding: 10px;background: var(--v-sidebarColorLight-darken2); overflow: hidden;margin-bottom: 8px">
      <v-row no-gutters style="height: 41px;">
        <!-- Left column: K Number search -->
        <v-col cols="auto" style="display: flex; width: 160px;">
          <v-text-field ref="searchTerm" v-model="searchTerm" label="K Number" hide-details="auto" outlined dark dense
            :disabled="kNumber === null"
            style="max-width: 120px; border-top-right-radius: 0; border-bottom-right-radius: 0;"></v-text-field>
          <v-btn small height="40px"
            style="width: 40px; min-width: 40px; border-top-left-radius: 0; border-bottom-left-radius: 0;"
            color="primary" :disabled="!fetchingAPIData && !searchTerm" :loading="fetchingAPIData"
            @click="fetchAPIData">
            <v-icon v-if="!kNumber">mdi-magnify</v-icon>
            <v-icon v-else>mdi-refresh</v-icon>
          </v-btn>
        </v-col>

        <!-- Middle column: Audience select -->
        <v-col v-show="apiDataLoaded" style="flex-grow: 1; padding: 0 10px;min-width: 0px !important;">
          <v-select v-show="!incomingRouteRequest" v-model="selection.routeID"
          :items="initializedSupportInfo.routeRequest" 
          item-text="name"
          item-value="id"
          label="Route Audience Query" 
          hide-details 
          outlined 
          dense
          @click.stop
          :menu-props="{
            offsetY: true
          }"
          style="width: 100%" @change="updateFilters"
        >
          <!-- Selection slot -->
          <template v-slot:selection="{ item }">
            <div class="compact-content">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-row no-gutters>
                    <v-col cols="auto" class="d-flex align-center" style="height: 25px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip small class="height-100" color="darkgrey" label v-on="on">
                            v{{ item.routeVersion }}
                          </v-chip>
                        </template>
                        <span>Route Version</span>
                      </v-tooltip>
                    </v-col>
                    <v-col style="min-width: 0; overflow: hidden;">
                      <div class="title-text" style="padding-left:5px;margin-top:3px;">
                        {{ item.description ? item.description : item.formula }}
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <span>{{ item.demographicCustom || 'No custom query' }}</span>
              </v-tooltip>
            </div>
            </template>
  
          <!-- Item slot -->
          <template v-slot:item="{ item }">
            <div class="compact-content">
              <v-row no-gutters>
                <v-col cols="auto" class="d-flex align-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip small class="height-100" color="darkgrey" label v-on="on">
                        v{{ item.routeVersion }}
                      </v-chip>
                    </template>
                    <span>Route Version</span>
                  </v-tooltip>
                </v-col>
                <v-col class="pl-2 text-truncate">
                  <div class="title-text text-truncate" style="white-space: normal !important;">
                    {{ item.description ? item.description : item.formula }}
                  </div>
                  <div class="formula-container text-truncate">
                    <DemographicLexer :value="item.formula" display-only hideMessages class="compact-lexer" />
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-select>
        </v-col>

        <!-- Right column: Buttons -->
        <v-col v-show="apiDataLoaded" cols="auto" style="display: flex; width: 166px;">
          <v-btn ref="filterButton" @click="isFilterExpanded = !isFilterExpanded"
            :color="filtersActive ? '#499f68' : undefined" small
            style=" min-width: 40px; height: 40px; margin-right: 10px;transition: background-color 250ms, border-color 250ms;">
            <v-icon>mdi-filter</v-icon>
            <v-icon right>{{ isFilterExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>

          <v-menu offset-y nudge-left="15">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small :loading="downloadingReport" v-bind="attrs" v-on="on"
                style="width: 40px; min-width: 40px; height: 40px; margin-right: 10px;">
                <v-icon>mdi-file-download-outline</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="downloadReport('Daily')">
                <v-list-item-title>Daily</v-list-item-title>
              </v-list-item>
              <v-list-item @click="downloadReport('Hourly')">
                <v-list-item-title>Hourly</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn small @click="toggleFullscreen" style="width: 40px; min-width: 40px; height: 40px;">
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div v-show="isFilterExpanded" ref="overlay">
          <v-row dense>
            <v-col cols="12" md="4">
              <DatePicker
                v-if="apiDataLoaded" 
                v-model="datePicker" 
                ref="datePicker" 
                mode="date" 
                is-dark 
                is-range 
                is-expanded 
                borderless
                is-required
                title-position="left" 
                :from-date="defaultDateTimeRange[0]"
                :min-date="defaultDateTimeRange[0]" 
                :max-date="defaultDateTimeRange[1]"
                @input="updateDateRange">                
                <div slot="footer" slot-scope="data" class="slot">
                  <div style="height:95px;">
                    <PlayoutBrushChart :brushData="brushData" :dateTimeRange="brushDateTimeRange"
                      style="margin-top:-30px;" @selectionChanged="handleBrushSelectionChanged" />
                  </div>
                  <div class="playout-overline"
                    style="letter-spacing: 1px;font-size: 12px;color: white;text-align: center;padding: 10px 23px;margin-top:-2px">
                    {{formattedDateRange}}
                  </div>
                  <v-btn v-show="selection.dateTimeRange.length > 0" @click="resetSelectedDateTimeRange" icon x-small
                    fab style="position: absolute;bottom: 1px;right: 0px;">
                    <v-icon small>mdi-refresh</v-icon>
                  </v-btn>
                </div>
              </DatePicker>
            </v-col>
            <v-col cols="12" md="8">
              <v-row dense>
                <v-col cols="12" sm="6">
                  <div class="playout-overline">Formats</div>

                  <v-autocomplete v-model="selection.formatDelivers" class="playout-filter"
                    :items="initializedSupportInfo.formatDeliver" item-text="name" item-value="id" label="Formats"
                    multiple outlined dense hide-details chips deletable-chips small-chips
                    @change="liveFilter ? updateFilters() : null">
                  </v-autocomplete>

                  <v-autocomplete v-model="selection.formatGroups" class="playout-filter"
                    :items="initializedSupportInfo.formatGroup" item-text="name" item-value="id" label="Format Groups"
                    multiple outlined dense hide-details chips deletable-chips small-chips
                    @change="liveFilter ? updateFilters() : null">
                  </v-autocomplete>

                  <v-autocomplete v-model="selection.creativeNames" class="playout-filter"
                    :items="initializedSupportInfo.creativeName" item-text="name" item-value="id" label="Creative Names"
                    multiple outlined dense hide-details chips deletable-chips small-chips
                    @change="liveFilter ? updateFilters() : null">
                  </v-autocomplete>

                  <div class="playout-overline">Media</div>

                  <v-autocomplete v-model="selection.mediaOwners" class="playout-filter"
                    :items="initializedSupportInfo.mediaOwner" item-text="name" item-value="id" label="Media Owners"
                    multiple outlined dense hide-details chips deletable-chips small-chips
                    @change="liveFilter ? updateFilters() : null">
                  </v-autocomplete>

                  <v-autocomplete v-model="selection.salesCodes" class="playout-filter"
                    :items="initializedSupportInfo.salesCode" item-text="name" item-value="id" label="Sales Codes"
                    multiple outlined dense hide-details chips deletable-chips small-chips
                    @change="liveFilter ? updateFilters() : null">
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6">
                  <div class="playout-overline">Locations</div>
                  <v-autocomplete v-model="selection.tvRegions" class="playout-filter"
                    :items="initializedSupportInfo.tvRegion" item-text="name" item-value="id" label="TV Regions"
                    multiple outlined dense hide-details chips deletable-chips small-chips
                    @change="liveFilter ? updateFilters() : null">
                  </v-autocomplete>

                  <v-autocomplete v-model="selection.conurbations" class="playout-filter"
                    :items="initializedSupportInfo.conurbation" item-text="name" item-value="id" label="Conurbations"
                    multiple outlined dense hide-details chips deletable-chips small-chips
                    @change="liveFilter ? updateFilters() : null">
                  </v-autocomplete>

                  <v-autocomplete v-model="selection.towns" class="playout-filter" :items="initializedSupportInfo.town"
                    item-text="name" item-value="id" label="Towns" multiple outlined dense hide-details chips
                    deletable-chips small-chips @change="liveFilter ? updateFilters() : null">
                  </v-autocomplete>

                  <v-row dense>
                    <v-col cols="6">
                      <v-autocomplete v-model="selection.addresses" class="playout-filter"
                        :items="initializedSupportInfo.address" item-text="name" item-value="id" label="Addresses"
                        multiple outlined dense hide-details chips deletable-chips small-chips
                        @change="liveFilter ? updateFilters() : null">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete v-model="selection.postCodes" class="playout-filter"
                        :items="initializedSupportInfo.postCode" item-text="name" item-value="id" label="Post Codes"
                        multiple outlined dense hide-details chips deletable-chips small-chips
                        @change="liveFilter ? updateFilters() : null">
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <div class="playout-overline">Descriptions</div>

                  <v-row dense>
                    <v-col cols="6">
                      <v-autocomplete v-model="selection.locationDescs" class="playout-filter"
                        :items="initializedSupportInfo.locationDesc" item-text="name" item-value="id"
                        label="Location Descriptions" multiple outlined dense hide-details chips deletable-chips
                        small-chips @change="liveFilter ? updateFilters() : null">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete v-model="selection.envDescs" class="playout-filter"
                        :items="initializedSupportInfo.envDesc" item-text="name" item-value="id"
                        label="Environmental Descriptions" multiple outlined dense hide-details chips deletable-chips
                        small-chips @change="liveFilter ? updateFilters() : null">
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row style="height: 40px;margin-bottom: 2px;">
                <span
                  style="text-transform: uppercase;margin-left: 15px;font-weight: 900;letter-spacing: 5px;color: #414141;text-align: center;pointer-events: none;padding-top: 10px;">
                  <span>Playout</span>
                  <v-icon style="padding-left: 5px; margin-top: -2px;color:inherit;">mdi-animation-play-outline</v-icon>
                </span>
                <div style="display: flex;position: absolute;right: 10px;">
                  <v-switch v-model="liveFilter" style="margin-top: 5px" inset
                    @change="liveFilter ? updateFilters() : null"></v-switch>
                  <v-btn height="40" @click="resetFilters(false)" style="flex: 2; margin-right: 10px;"
                    color="#9f3838"><v-icon>mdi-filter-remove</v-icon></v-btn>
                  <v-btn height="40" @click="updateFilters" color="#499f68"><v-icon>mdi-filter-check</v-icon></v-btn>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </v-card>
    <div v-show="apiDataLoaded">
      <v-row dense>
        <v-col :key="1" cols="12" :md="getColSize">
          <PlayoutKPI :item="getKPIItem('Plays')" />
        </v-col>

        <v-col v-if="selection.routeID !== null" :key="2" cols="12" :md="getColSize">
          <PlayoutKPI :item="getKPIItem('Impacts')" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" :sm="verificationLite ? 12 : 8">
          <v-card style="background: var(--v-sidebarColorLight-darken2);height: 500px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;"> Master</span>
            </div>
            <div style="width: 100%;height: calc(100% - 51px);    padding: 0px 26px 34px 15px;">
              <PlayoutMasterChart :masterData="uiData.masterChartData" style="width: 100%; height: 100%;" />
            </div>
          </v-card>
        </v-col>
        <v-col v-if="!verificationLite" cols="12" sm="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2);height: 500px;display: flex; margin:0px">
            <PlayoutMap :mapData="uiData.mapData" style="width: 100%; height: 100%;" />
          </v-card>
        </v-col>
      </v-row>
      <v-row dense v-if="!verificationLite">
        <v-col key="1" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;">Media
                Owners</span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px);">
              <PlayoutGroupTypeChart :groupTypeData="uiData.mediaOwner" />
            </div>
          </v-card>
        </v-col>
        <v-col key="2" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;">Formats on
                Deliver
              </span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px);">
              <PlayoutGroupTypeChart :groupTypeData="uiData.formatDeliver" />
            </div>
          </v-card>
        </v-col>
        <v-col key="3" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;">TV
                Regions</span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px)">
              <PlayoutGroupTypeChart :groupTypeData="uiData.tvRegion" />
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="false" dense>
        <v-col v-if="uiData.formatGroup" key="4" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;">Format
                Groups</span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px);">
              <PlayoutGroupTypeChart :groupTypeData="uiData.formatGroup" />
            </div>
          </v-card>
        </v-col>
        <v-col v-if="uiData.creativeName" key="5" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;">Creative Names
              </span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px);">
              <PlayoutGroupTypeChart :groupTypeData="uiData.creativeName" />
            </div>
          </v-card>
        </v-col>
        <v-col v-if="uiData.conurbation" key="6" cols="12" lg="4">
          <v-card style="background: var(--v-sidebarColorLight-darken2); height: 400px; margin:0px">
            <div style="padding: 20px 20px 10px 20px;">
              <span
                style="text-transform: uppercase;font-weight: 500;letter-spacing: 4px; color: grey;">Conurbations</span>
            </div>
            <div style="padding: 10px 20px 20px 20px;height: calc(100% - 51px)">
              <PlayoutGroupTypeChart :groupTypeData="uiData.conurbation" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// Services
import ReportingController from '@/services/controllers/Reporting'

// Components
import PlayoutMap from './PlayoutMap.vue';
import PlayoutBrushChart from './PlayoutBrushChart.vue';
import PlayoutMasterChart from './PlayoutMasterChart.vue';
import PlayoutMiniChart from './PlayoutMiniChart.vue';
import PlayoutGroupTypeChart from './PlayoutGroupTypeChart.vue';
import PlayoutFilters from './PlayoutFilters.vue';
import DemographicLexer from '@/components/_theme/demographicLexer/DemographicLexer.vue'

// New daterange component
import { Calendar, DatePicker } from 'v-calendar';

import PlayoutKPI from './PlayoutKPI.vue';
import PlayoutWorker from 'worker-loader!./playoutWorker.js';

export default {
  name: 'PlayoutDashboard',
  props: {
    kNumber: {
      type: String,
      required: false
    },
    incomingRouteRequest: {
      type: Object,
      default: null
    },
    //Verification Lite
    verificationLite: {
      type: Boolean,
      default: false
    },
    hideTargets: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PlayoutMap,
    PlayoutBrushChart,
    PlayoutMasterChart,
    PlayoutMiniChart,
    PlayoutGroupTypeChart,
    PlayoutFilters,
    PlayoutKPI,
    Calendar,
    DatePicker,
    DemographicLexer
  },
  data: () => ({
    apiData: {},
    apiDataLoaded: false,
    // UI Colours
    uiColours: {
      plays: {
        daily: '#4ecdc4e0',
        cumulative: '#25625ecc'
      },
      impacts: {
        daily: '#c7f464e0',
        cumulative: '#344a03cc'
      }
    },

    searchTerm: '',
    loadedSearchTerm: '',
    granularity: 'Daily',
    fetchingAPIData: false,
    downloadingReport: false,

    //filters
    isFilterExpanded: false,

    //Selection
    selection: {
      routeID: null,
      dateTimeRange: [], // example datetime range ["2024-01-16T00:00:00", "2024-01-20T00:00:00"]
      mediaOwners: [],
      formatDelivers: [],
      conurbations: [],
      tvRegions: [],
      creativeNames: [],
      salesCodes: [],
      formatGroups: [],
      locationDescs: [],
      envDescs: [],
      addresses: [],
      postCodes: [],
      towns: [],
    },

    liveFilter: false,

    //date picker 
    datePicker: {
      startDate: null,
      endDate: null
    },

    dateTimeRange: {
      startDate: null,
      endDate: null
    },
    brushDateTimeRange: {
      start: null,
      end: null
    },

    //UI data
    uiData: { masterChartData: { series: [{ name: "Plays - Daily", type: "area", data: [] }, { name: "Plays - Cumulative", type: "column", data: [] }], categories: [] }, mediaOwner: { series: [{ name: "Plays", data: [] }], categories: [] }, formatDeliver: { series: [{ name: "Plays", data: [] }], categories: [] }, tvRegion: { series: [{ name: "Plays", data: [] }], categories: [] }, totals: { plays: 0, impacts: 0 }, mapData: { data: [], maxIntensity: 0 } },
    isLoading: false,
    filtersActive: false,
  }),
  watch: {
    incomingRouteRequest(newVal) {
    if (newVal) {
      // Check if apiData and supportInfo exist before accessing routeRequest
      if (this.apiData?.supportInfo?.routeRequest) {
        //check if there are any matching ids
        let matchingIds = this.apiData.supportInfo.routeRequest.filter(r => r.id === newVal.id);
        if (matchingIds.length > 0) {
          this.selection.routeID = newVal.id;
        }
        else {
          this.selection.routeID = null;
        }
        this.updateFilters();
      } else {
        // Handle the case when apiData or supportInfo is not yet loaded
        this.selection.routeID = null;
      }
    }
  },
    filteredSupportInfo(newInfo) {
      // Select Route Request for first time loading
      if (this.selectedRouteRequest.length === 0) {
        const newRouteRequest = newInfo.get('routeRequest');
        if (!newRouteRequest) {
          return;
        }
        for (const [id, item] of newRouteRequest.entries()) {
          if (item.name.includes('All Adult')) {
            this.selectedRouteRequest = id;
            break;
          }
        }
      }
    },
  },
  created() {
    // WEB WORKER BLOCK
    this.worker = new PlayoutWorker();
    //recieve post message from worker
    this.worker.onmessage = (event) => {
      switch (event.data.action) {
        case 'update': {
          this.uiData = event.data.data;
          this.apiDataLoaded = true;
          break;
        }
        case 'error': {
          this.$root.$emit("snackbarError", event.data.data + " for " + this.searchTerm);
        }
        default:
          console.log('Unknown action:', event.data.action);
      }
    };
  },
  mounted() {
    if (this.kNumber) {
      // if a kNumber is provided, search for it
      this.searchTerm = this.kNumber;
      this.fetchAPIData();
    }
  },

  computed: {
    formattedDateRange() {
  if (!this.datePicker?.start || !this.datePicker?.end) {
    return '';
  }
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };
  
  const startDate = formatDate(this.datePicker.start);
  const endDate = formatDate(this.datePicker.end);
  
  return `${startDate} - ${endDate}`;
},
    getColSize() {
      if (this.selection.routeID !== null) return 6;
      return 12;

    },
    getKPIItem() {
      return function (name) {
        if (typeof name !== 'string') {
          console.warn('getKPIItem: name is not a string', name);
          return null;
        }

        const item = {
          name: name,
          actuals: this.uiData.totals[name.toLowerCase()] || 0,
        };

        if (!this.verificationLite && !this.hideTargets) {
          item.targets = null;
          item.performance = null;
        }

        // Find the correct series data based on the name
        const seriesData = this.uiData.masterChartData.series.find(series =>
          series.name.toLowerCase().startsWith(name.toLowerCase()) && series.name.toLowerCase().includes('daily')
        );

        item.miniChartData = {
          categories: this.uiData.masterChartData.categories,
          series: [{
            data: seriesData ? seriesData.data.map(value => value === null ? 0 : value) : [], // Replace null with 0
            color: this.uiColours[name.toLowerCase()].daily.slice(0, 7) + '66'  // Take first 7 chars (including #) and add 66
          }]
        };

        return item;
      }
    },

    brushData() {
      if (!this.apiData || !this.apiData.data || this.apiData.data.length === 0) {
        return { series: [], categories: [] };
      }

      const categories = [];
      const playsData = [];

      for (const item of this.apiData.data) {
        categories.push(item.dateTime);
        var totalPlays = 0;
        for (const frame of item.frames) {
          totalPlays += frame.plays;
        }
        playsData.push(totalPlays);
      }

      return {
        series: [{ name: 'Plays', data: playsData }],
        categories: categories
      };
    },

    defaultDateTimeRange() {
      // Check if this.apiData exists and has a non-empty 'data' array
      if (this.apiData && this.apiData.data && this.apiData.data.length > 0) {
        var minDate = new Date(this.apiData.data[0].dateTime);
        var maxDate = minDate;
        const apiDataLength = this.apiData.data.length;
        if (apiDataLength > 1) {
          maxDate = new Date(this.apiData.data[apiDataLength - 1].dateTime);
        }
        this.datePicker = {
          start: minDate,
          end: maxDate
        }
        return [
          minDate,
          maxDate
        ];
      } else {
        // Handle the case where this.apiData is null/undefined or data is empty
        return [];
      }
    },

    initializedSupportInfo() {
      return {
        frameInfo: this.apiData.supportInfo?.frameInfo || [],
        frameId: this.apiData.supportInfo?.frameId || [],
        creativeName: this.apiData.supportInfo?.creativeName || [],
        mediaOwner: this.apiData.supportInfo?.mediaOwner || [],
        formatDeliver: this.apiData.supportInfo?.formatDeliver || [],
        salesCode: this.apiData.supportInfo?.salesCode || [],
        formatGroup: this.apiData.supportInfo?.formatGroup || [],
        locationDesc: this.apiData.supportInfo?.locationDesc || [],
        envDesc: this.apiData.supportInfo?.envDesc || [],
        address: this.apiData.supportInfo?.address || [],
        postCode: this.apiData.supportInfo?.postCode || [],
        town: this.apiData.supportInfo?.town || [],
        tvRegion: this.apiData.supportInfo?.tvRegion || [],
        conurbation: this.apiData.supportInfo?.conurbation || [],
        routeRequest: this.apiData.supportInfo?.routeRequest || [],
      };
    },
  },

  methods: {
    async fetchAPIData() {
      //Reset UI data
      this.resetFilters(true);

      this.fetchingAPIData = true;
      this.apiDataLoaded = false;
      this.loadedSearchTerm = '';
      await ReportingController.getPlayoutReportExpanded(this.searchTerm, 0, 'Daily')
        .then((res) => {
          this.loadedSearchTerm = this.searchTerm;
          if (res.data && res.data.data && res.data.data.length >= 1) {
            this.apiData = res.data;
            this.initialisePlayoutWorker();
          }
          else {
            this.$root.$emit("snackbarError", "No Playout data found for " + this.searchTerm);
          }
        }).catch((err) => {
          this.$root.$emit("snackbarError", err);

        }).finally(() => {
          this.fetchingAPIData = false;
        });
    },
    initialisePlayoutWorker() {
      // Update dropdown selection based on support info
      if (this.apiData.supportInfo?.routeRequest?.length > 0 && !this.incomingRouteRequest) {
        this.selection.routeID = this.apiData.supportInfo?.routeRequest[0].id;
      }
      else if (this.incomingRouteRequest) {
        //check if there are any matching ids
        let matchingIds = this.apiData.supportInfo.routeRequest.filter(r => r.id === this.incomingRouteRequest.id);
        if (matchingIds.length > 0) {
          this.selection.routeID = matchingIds[0].id;
        }
      }
      // Post message to worker
      this.worker.postMessage({
        action: 'initialise',
        apiData: this.apiData,
        verificationLite: this.verificationLite,
        selection: this.selection,
        uiColours: this.uiColours
      });
    },
    updateDateRange() {
    if (this.datePicker && this.datePicker.start && this.datePicker.end) {
      const start = new Date(this.datePicker.start);
      const end = new Date(this.datePicker.end);

      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);

      const startDateString = start.toISOString();
      const endDateString = end.toISOString();

      //check if start and end dates are the same as selection.dateTimeRange
      if (startDateString === this.selection.dateTimeRange[0] && endDateString === this.selection.dateTimeRange[1]) {
        return;
      }

      this.selection.dateTimeRange = [
        startDateString,
        endDateString
      ];

      this.brushDateTimeRange = {
        start: start,
        end: end
      };

      if (this.liveFilter) {
        this.updateFilters();
      }

    }
  },
    handleBrushSelectionChanged(newSelection) {
    if (newSelection && newSelection.length === 2) {
      // Update selection.dateTimeRange
      const start = new Date(newSelection[0]);
      const end = new Date(newSelection[1]);

      start.setUTCHours(0, 0, 0, 0);
      end.setUTCHours(0, 0, 0, 0);

      const startDateString = start.toISOString();
      const endDateString = end.toISOString();

      //check if start and end dates are the same as selection.dateTimeRange
      if (startDateString === this.selection.dateTimeRange[0] && endDateString === this.selection.dateTimeRange[1]) {
        return;
      }

      this.selection.dateTimeRange = newSelection;

      // Update datePicker
      this.datePicker = {
        start: newSelection[0],
        end: newSelection[1]
      };

      if (this.liveFilter) {
        this.updateFilters();
      }

    }
  },
    resetSelectedDateTimeRange() {
      this.selection.dateTimeRange = [];
      this.datePicker, this.brushDateTimeRange = {
        start: this.defaultDateTimeRange[0],
        end: this.defaultDateTimeRange[1]
      };
    },
    getChartName(param) {
      const chartNames = {
        mediaOwner: 'Media Owner',
        formatDeliver: 'Formats on Deliver',
        tvRegion: 'TV Regions',
        creativeName: 'Creatives',
        salesCode: 'Sales Codes',
        formatGroup: 'Format Groups',
        locationDesc: 'Location Descriptions',
        envDesc: 'Environmental Descriptions',
        address: 'Addresses',
        postCode: 'Post Codes',
        town: 'Towns',
        conurbation: 'Conurbations'
      };
      return chartNames[param] || 'Chart';
    },
    toggleFullscreen() {
      const elem = this.$refs.playoutDashboardContainer;

      if (!document.fullscreenElement) {
        elem.requestFullscreen().catch(err => {
          console.error('Failed to enter fullscreen mode:', err);

        });
        // add class playout-dashboard-container-fullscreen to elem
        elem.classList.add('playout-dashboard-container-fullscreen');


      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          elem.classList.remove('playout-dashboard-container-fullscreen');
        }
      }
    },
    updateFilters() {
      if (!this.apiData || !this.selection) { return; }
      if (
        this.selection.dateTimeRange.length > 0 ||
        this.selection.mediaOwners.length > 0 ||
        this.selection.formatDelivers.length > 0 ||
        this.selection.conurbations.length > 0 ||
        this.selection.tvRegions.length > 0 ||
        this.selection.creativeNames.length > 0 ||
        this.selection.salesCodes.length > 0 ||
        this.selection.formatGroups.length > 0 ||
        this.selection.locationDescs.length > 0 ||
        this.selection.envDescs.length > 0 ||
        this.selection.addresses.length > 0 ||
        this.selection.postCodes.length > 0 ||
        this.selection.towns.length > 0
      ) {
        this.filtersActive = true;
      }
      else {
        this.filtersActive = false;
      };
      if (!this.liveFilter) {
        this.isFilterExpanded = false;
      }

      this.worker.postMessage({
        action: 'updateSelection',
        selection: this.selection
      });
    },
    resetFilters(apiReset) {
      this.datePicker = {
        start: this.defaultDateTimeRange[0],
        end: this.defaultDateTimeRange[1]
      }
      this.brushDateTimeRange = this.datePicker;
      this.selection.dateTimeRange = [];
      this.selection.mediaOwners = [];
      this.selection.formatDelivers = [];
      this.selection.conurbations = [];
      this.selection.tvRegions = [];
      this.selection.creativeNames = [];
      this.selection.salesCodes = [];
      this.selection.formatGroups = [];
      this.selection.locationDescs = [];
      this.selection.envDescs = [];
      this.selection.addresses = [];
      this.selection.postCodes = [];
      this.selection.towns = [];
      this.filtersActive = false;

      if (apiReset) {
        this.liveFilter = false;
        this.isFilterExpanded = false;
      }
      else {
        if (!this.liveFilter) {
          this.isFilterExpanded = false;
        }

        this.worker.postMessage({
          action: 'updateSelection',
          selection: this.selection
        });
      }
    },

    async downloadReport(granularity) {
      this.downloadingReport = true;
      let res = await ReportingController.getPlayoutReportExport(this.loadedSearchTerm, 0, granularity).catch(async err => {

      }).finally(() => {
        this.downloadingReport = false;
      })
      const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"', '').split('\\')
      const fileName = folderPath[folderPath.length - 1]
      const blob = new Blob([res.data])
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    },
  },
  beforeDestroy() {
    // Clean up any event listeners or intervals
    // WEB WORKER BLOCK
    if (this.worker) {
      this.worker.terminate();
    }
  }
}
</script>

<style scoped>

.playout-filter {
  margin-top: 6px;
  height: 40px;
}

.playout-overline {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 3px;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase !important;
  color: #ffffff59;
  margin-top: 6px;
}

.playout-dashboard-container {
  background-color: #1e1e1e
}

.playout-dashboard-container-fullscreen {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;

}

::v-deep .vc-container {
    --gray-600: #2b2b2b !important;
    --blue-500: var(--v-primary-base) !important;
    --rounded-full: 3px !important;
    background-color: transparent !important;
    border-color: #454545 !important;
    height: 321px !important;
    margin-top: 7px !important;
    border-radius: 4px !important;
    font-family: 'Roboto' !important;
    width: 100% !important;
}

::v-deep .vc-title{
  font-size: 14px !important;
}

::v-deep .vc-day-content:focus{
  background-color: inherit !important;
}

::v-deep .vc-weeks[data-v-74ad501d] {
    width: 100% !important;
    min-width: 10px !important;
}

::v-deep .vc-pane {
  width: 100% !important;
  min-width: 10px !important;
}

::v-deep .v-select__selections {
  height: 40px !important;
  overflow-y: auto !important;
}

::v-deep .vc-day {
  min-height: 24.5px !important;
  height: 24.5px !important;
}

::v-deep .vc-highlight {
    height: 100% !important;
    width: 100% !important;
    transition: all 750ms !important;
}

::v-deep .vc-day-content {
  width: 100% !important;
  height: 100% !important;
  line-height: 100% !important;
}

</style>

<style scoped>
.compact-content {
width: 100%;
overflow: hidden;
}

.title-text {
  font-size: 1rem;
font-weight: 500;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
line-height: 20px;
}

.formula-container {
margin-top: -2px;
filter: grayscale(0.3);
font-size: 0.875rem;
color: rgba(255, 255, 255, 0.7);
}

.height-100 {
height: 100% !important;
width: 48px;
}

::v-deep .v-list-item {
padding: 4px 16px !important;
}

/* Add this new class */
::v-deep .v-select__selections {
max-height: 40px;
overflow: hidden;
}

/* Add this to ensure the row stays within bounds */
.v-row.no-gutters {
max-width: 100%;
margin: 0;
}

/* Ensure the column doesn't overflow */
.v-col {
  min-width: 0;
  overflow: hidden;
}
</style>